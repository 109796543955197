<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { durationLeft } = useScretchCardData({ immediate: !isGuest.value });

const handleCardClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/scratch-cards", { external: true });
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleButtonClick = () => {
	loginGuard({
		success: () => {
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<ACard
		class="card-scratch-cards"
		width="584px"
		height="760px"
		:imgAttrs="{
			width: 584,
			height: 760,
			src: '/nuxt-img/scratch-cards/promo/promo-bg.png'
		}"
		alt="scratch-card"
		@click="handleCardClick"
	>
		<AText class="countdown" :modifiers="['uppercase', 'center']" as="div">
			<template v-if="isGuest">{{ t("Register to Play") }}</template>
			<template v-else>
				{{ t("Ends in") }}&nbsp;<b>{{ durationLeft }}</b>
			</template>
		</AText>

		<template #bottom>
			<LazyNuxtImg
				class="logo"
				src="/nuxt-img/scratch-cards/promo/logo.png"
				width="230"
				height="145"
				quality="100"
				format="webp"
				alt="promo-logo"
			/>
			<AText variant="topeka" :modifiers="['semibold', 'center', 'uppercase']">
				{{ t("up to") }}
			</AText>
			<div class="prizes">
				<MPrizeFund variant="coins" icon="coins" is-svg :iconSize="24">
					<AText variant="tirana" class="prize-text" :modifiers="['semibold']">
						{{ numberFormat(1000000) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund variant="entries" icon="entries" is-svg :iconSize="24">
					<AText variant="tirana" class="prize-text" :modifiers="['semibold']">
						{{ numberFormat(10000) }}
					</AText>
					<AText class="free" :modifiers="['semibold', 'uppercase']"> {{ t("for free") }}</AText>
				</MPrizeFund>
			</div>
			<LazyNuxtImg
				class="img-scratch"
				src="/nuxt-img/scratch-cards/promo/scratch.png"
				width="584"
				height="392"
				alt="promo-scratch"
			/>
			<AButton class="button" variant="primary" size="xl" @click.stop="handleButtonClick">
				<AText variant="turin" :modifiers="['medium']">
					{{ t("Play Now!") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-scratch-cards {
	width: 292px;
	height: 380px;

	.logo {
		width: 115px;
		height: 72px;
		margin-bottom: gutter(0.5);
	}

	.img-scratch {
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		width: 280px;
		height: 186px;
		z-index: -1;
	}
}

.countdown {
	position: relative;
	padding: gutter(1);
	width: calc(100% - 24px);
	height: 34px;
	background: rgba(var(--chattogram-rgb), 0.4);
	margin: 0 auto;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	z-index: 3;
}
.prizes {
	text-align: center;
	margin-bottom: gutter(16.75);
	.free {
		max-width: 36px;
	}
}
button.primary {
	width: 100%;
	position: relative;
	z-index: 2;
}
</style>
